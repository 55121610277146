.subscriptions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    align-items: initial !important;
    margin-bottom: 20px;
    padding: 0 20px;
  }


  .subscriptions-grid .table-container{
    min-height: 500px;
  }
  
