.image-preview {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.image-preview img {
  max-width: 100px;
  border-radius: 4px;
}

.remove-image-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
}

.answer-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.answer-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.answer-checkbox {
  margin-right: 0.5rem;
}

.answer-action-btn {
  margin-right: auto;
  background: none;
  border: none;
  cursor: pointer;
}

.answer-action-btn2 {
  padding: 5px 19px;
  background: #007bff;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
}

.remove-answer-btn {
  margin-left: 0.5rem;
}

.add-answer-btn {
  margin-top: 1rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.form-submit-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.form-submit-btn:hover {
  background-color: #218838;
}

.qetaa-display img {
  width: 100%;
  max-height: 280px;
  object-fit: contain;
}
