.loader-container {
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }
  
  .loader-wrap {
    width: 100px;
    /* height: 100px; */
    display: grid;
    place-content: center;
  }
  
  
  /* --------------------------------- */
  
  .loader-1 {
      border: 16px solid var(--green); 
      border-top: 16px solid #848080; 
      border-radius: 50%;
      width: 25px;
      height: 25px;
      animation: spin 2s linear infinite;
  }
    
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
  
  /* .loader-2 ---------------------- */
  
  .loader-2 {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  
  .loader-2:after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: var(--pink) transparent var(--peach) transparent;
    animation: loader-2-dual-spin 2s linear infinite;
  }
  
  @keyframes loader-2-dual-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* .loader-3 ---------------------- */
  
  .loader-3 {
    display: inline-block;
    align-items: center;
    justify-content: space-around;
    width: 80px;
    height: 20px;
  }
  
  .loader-3 div {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    background-color: var(--main-color);
    border-radius: 50%;
    display: inline-block;
    animation: loader-3-bounce 1.4s infinite ease-in-out both;
  }
  
  .loader-3 div:nth-child(1) { animation-delay: -0.32s; }
  .loader-3 div:nth-child(2) { animation-delay: -0.16s; }
  
  @keyframes loader-3-bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
}  