header {
  margin: 20px 0;
  height: 90px;
  display: flex;
  
  height: 80px;
  
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 3px;
  color: black;
  position: relative;
  margin-top: 0;
  background: white;
  box-shadow: 1px 9px 24px -2px #8080803b;
 
}

header.header {
  position: sticky;
  top: 0;
  z-index: 60;
}
.search-container {
  position: relative;
  width: 250px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 0.1px solid;
  border-radius: 20px;
  transition: width 0.3s ease;
  font-size: 1rem;
}

.search-input:focus {
  width: 300px;
  outline: none;
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-name {
  margin-right: 10px;
  font-weight: bold;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  transition: transform 0.3s ease;
}

.profile-info:hover .arrow-down {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  display: flex !important;
  top: 60px;
  right: 0;
  background-color: #34495e;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease;
  transform: translateY(0);
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: var(--main-color);
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.table-container{
  margin-bottom: 29px;
}