@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  
  box-sizing: border-box;
  position: relative;
  font-family: "Roboto", sans-serif !important;
}

:root {
  --main-color: #0899d3;
}
